import { OrganizationUnitResponse } from 'api/models';
import { SelectField, SelectOption } from 'components/FormControl';
import Translations from 'i18n/translation';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import { Form, FormGroup } from 'react-bootstrap';
import { lang } from 'i18n/lang';
import { Circle, PlusCircleFill } from 'react-bootstrap-icons';
import { AddButton } from 'components/Button';
import usePromptInputText from 'components/Modal/PromptInputText';
import raApi from 'api/v2/risk-assessment/raApi';
import raTagApi from 'api/v2/risk-assessment/raTagApi';
import { RATagResponse } from 'api/v2/risk-assessment/model/rs.Response.model';
import Select from 'components/FormControl/Select';

export interface RATagSelectProps extends BaseFormControlProps {
    isMulti: boolean | undefined;
    defaultValue?: any;
    isCanCreate?: boolean;
}

export function RATagSelect({ isCanCreate = false, name, control, required, md = 4, label, readOnly, isMulti, defaultValue }: RATagSelectProps) {
    const [options, setOptions] = useState<SelectOption[]>([]);
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
    });
    const { openPromptInputText, PromptInputText } = usePromptInputText();

    const onAddTag = async () => {
        const text: string = await openPromptInputText({
            title: t(Translations.common.name),
            defaultValue: '',
        });
        if (text)
            raTagApi
                .create({ name: text })
                .then((id: string) => {
                    setOptions([...options, { value: id, label: text }]);
                })
                .catch((res) => {});
    };

    useEffect(() => {
        raTagApi
            .getTag()
            .then((tags: RATagResponse[]) => {
                setOptions(tags.toSelectOptions('name', 'id'));
            })
            .catch((e) => {});
    }, []);
    return (
        <div className={`col-md-${md}`}>
            <Form.Group className={`mb-3 `}>
                <FormGroup>
                    <Form.Label>
                        {lang.text(Translations.ra.tag)}
                        {required && <span className="text-danger">*</span>} &nbsp;
                        {isCanCreate && (
                            <span className={`btn-inner cursor-pointer-hover`}>
                                <PlusCircleFill onClick={onAddTag} />
                            </span>
                        )}
                    </Form.Label>
                    <Select
                        name="tagIds"
                        onValueChange={(n) => {
                            console.log(n);
                        }}
                        required={required}
                        control={control}
                        options={options}
                        isMulti={isMulti}
                    />
                </FormGroup>
            </Form.Group>
            {PromptInputText}
        </div>
    );
}

export default RATagSelect;
