import actions from 'app/setting/actions';
import { version } from 'os';

const Translations = {
    error: {
        requireCompany: 'error.requireCompany',
        requiredAllFields: 'error.requiredAllFields',
        secRequiredOrg: 'error.secRequiredOrg',
    },
    common: {
        statusDisplay: {
            pending: 'common.statusDisplay.pending',
            approved: 'common.statusDisplay.approved',
            refused: 'common.statusDisplay.refused',
        },
        language: {
            name: 'common.language.name',
            en: 'common.language.en',
            vi: 'common.language.vi',
        },
        quantity: 'common.quantity',
        pic: 'common.pic',
        login: 'common.login',
        logout: 'common.logout',
        profile: 'common.profile',
        changePassword: 'common.changePassword',
        download: 'common.download',
        select: 'common.select',
        status: 'common.status',
        show: 'common.show',
        hide: 'common.hide',
        upload: 'common.upload',
        addNew: 'common.addNew',
        edit: 'common.edit',
        all: 'common.all',
        createdBy: 'common.createdBy',
        createdTime: 'common.createdTime',
        role: 'common.role',
        name: 'common.name',
        reportType: 'common.reportType',
        dailyReport: 'common.dailyReport',
        scheduleReport: 'common.scheduleReport',
        apply: 'common.apply',
        ok: 'common.ok',
        notOk: 'common.notOk',
        fromDate: 'common.fromDate',
        toDate: 'common.toDate',
        statusOpen: 'common.statusOpen',
        statusClosed: 'common.statusClosed',
        safe: 'common.safe',
        unSafe: 'common.unSafe',
        closedDate: 'common.closedDate',
        description: 'common.description',
        closedBy: 'common.closedBy',
        dashboard: 'common.dashboard',
        riskLevel: 'common.riskLevel',
        goldenRules: 'common.goldenRules',
        reportDetail: 'common.reportDetail',
        employee: 'common.employee',
        closeReport: 'common.closeReport',
        generalInfo: 'common.generalInfo',
        history: 'common.history',
        code: 'common.code',
        issuedDate: 'common.issuedDate',
        issuedPlace: 'common.issuedPlace',
        expireDate: 'common.expireDate',
    },
    legalDocument: {
        group: 'legalDocument.group',
        name: 'legalDocument.name',
        tag: 'legalDocument.tag',
        content: 'legalDocument.content',
        title: 'legalDocument.title',
        property: 'legalDocument.property',
        referenceNumber: 'legalDocument.referenceNumber',
        issuePlace: 'legalDocument.issuePlace',
        effectiveDateFrom: 'legalDocument.effectiveDateFrom',
        effectiveDateTo: 'legalDocument.effectiveDateTo',
        follower: 'legalDocument.follower',
        applyObject: 'legalDocument.applyObject',
        rules: 'legalDocument.rules',
        requirements: 'legalDocument.requirements',
    },
    userProfile: {
        employeeCode: 'userProfile.employeeCode',
        dateOfBirth: 'userProfile.dateOfBirth',
        permanentAddress: 'userProfile.permanentAddress',
        emergencyPhoneNumber: 'userProfile.emergencyPhoneNumber',
        height: 'userProfile.height',
        weight: 'userProfile.weight',
        bloodType: 'userProfile.bloodType',
        healthStatus: 'userProfile.healthStatus',
        healthCheckResultDate: 'userProfile.healthCheckResultDate',
        medicalRecord: 'userProfile.medicalRecord',
        trainingRecord: 'userProfile.trainingRecord',
        certificate: 'userProfile.certificate',
        course: 'userProfile.course',
        courseStartDate: 'userProfile.courseStartDate',
        profileIncentiveDisciplinaryDecisionMake: 'userProfile.profileIncentiveDisciplinaryDecisionMake',
        profileIncentiveDisciplinaryType: 'userProfile.profileIncentiveDisciplinaryType',
        profileIncentiveDisciplinary: 'userProfile.profileIncentiveDisciplinary',
        profileIncentiveDisciplinaryDate: 'userProfile.profileIncentiveDisciplinaryDate',
        courseOrganizeUnit: 'userProfile.courseOrganizeUnit',
    },
    role: {
        role: 'Phân quyền',
        // displayName:"Tên hiển thị"
    },
    sec: {
        gate: 'sec.gate',
        menuBar: 'sec.menuBar',
        register: 'sec.register',
        report: 'sec.report',
        checkInOut: 'sec.checkInOut',
        content: 'sec.content',
        dkshNumber: 'sec.dkshNumber',
        checkInTime: 'sec.checkInTime',
        checkOutTime: 'sec.checkOutTime',
        checkIn: 'sec.checkIn',
        checkOut: 'sec.checkOut',
        scanQRCode: 'sec.scanQRCode',
        scanQRCodeCheckIn: 'sec.scanQRCodeCheckIn',
        scanQRCodeCheckOut: 'sec.scanQRCodeCheckOut',
        object: 'sec.object',
        approve: 'sec.approve',
        unApprove: 'sec.unApprove',
    },
    wpr: {
        ppr: {
            name: 'wpr.ppr.name',
            type: 'wpr.ppr.type',
            statusTask: 'wpr.ppr.statusTask',
            descriptionTask: 'wpr.ppr.descriptionTask',
            taskList: 'wpr.ppr.taskList',
            task: 'wpr.ppr.task',
            object: 'wpr.ppr.object',
            level: 'wpr.ppr.level',
        },
        skpi: {
            skpiCreate: 'wpr.skpi.skpiCreate',
            skpiReview: 'wpr.skpi.skpiReview',
            objectives: 'wpr.skpi.objectives',
            actions: 'wpr.skpi.actions',
            addAction: 'wpr.skpi.addAction',
            saveObjectives: 'wpr.skpi.saveObjectives',
            closeObjectives: 'wpr.skpi.closeObjectives',
        },
    },
    kpis: {
        unit: 'kpis.unit',
        standard: 'kpis.standard',
        no: 'kpis.no',
        type: 'kpis.type',
        reportDate: 'kpis.reportDate',
    },
    document: {
        version: 'document.version',
        document: 'document.document',
        approvedDate: 'document.approvedDate',
        tag: 'document.tag',
        link: 'document.link',
        group: 'document.group',
        name: 'document.name',
    },
    ra: {
        controlMeasure: 'ra.controlMeasure',
        actionName: 'ra.actionName',
        hazard: 'ra.hazard',
        tag: 'ra.tag',
    },
    equipment: {
        checkList: 'equipment.checkList',
        type: 'equipment.type',
        equipment: 'equipment.equipment',
        dailyReportTemplate: 'form.equipment.dailyReportTemplate',
        scheduledReportTemplate: 'form.equipment.scheduledReportTemplate',
        operator: 'form.equipment.operator',
        supervisor: 'form.equipment.supervisor',
        technicalSpecifications: 'form.equipment.technicalSpecifications',
        frontView: 'form.equipment.frontView',
        sideView: 'form.equipment.sideView',
        templateCategories: 'form.equipment.templateCategories',
        templateGroupItem: 'form.equipment.templateGroupItem',
        templateGroupName: 'form.equipment.templateGroupName',
        templateGroupItemName: 'form.equipment.templateGroupItemName',
        autoSelectManager: 'form.equipment.autoSelectManager',
        reportPersonInCharge: 'form.equipment.reportPersonInCharge',
        reportNotes: 'form.equipment.reportNotes',
        reportRating: 'form.equipment.reportRating',
        reportRatingRed: 'form.equipment.reportRatingRed',
        reportRatingYellow: 'form.equipment.reportRatingYellow',
        reportRatingGreen: 'form.equipment.reportRatingGreen',
        equipmentName: 'form.equipment.equipmentName',
        equipmentSearch: 'form.equipment.equipmentSearch',
        addHeader: 'form.equipment.addHeader',
        updateHeader: 'form.equipment.updateHeader',
        detailInfo: 'form.equipment.detailInfo',
        code: 'form.equipment.code',
        name: 'form.equipment.name',
        brand: 'form.equipment.brand',
        manufacturingYear: 'form.equipment.manufacturingYear',
        serialNo: 'form.equipment.serialNo',
        inspectedDate: 'form.equipment.inspectedDate',
        expiredDate: 'form.equipment.expiredDate',
        inspector: 'form.equipment.inspector',

        validate: {
            nameCannotEmpty: 'form.equipment.validate.nameCannotEmpty',
            codeCannotEmpty: 'form.equipment.validate.codeCannotEmpty',
            brandCannotEmpty: 'form.equipment.validate.brandCannotEmpty',
            manufacturingYearCannotEmpty: 'form.equipment.validate.manufacturingYearCannotEmpty',
            equipmentCannotEmpty: 'form.equipment.validate.equipmentCannotEmpty',
        },
    },
    sor: {
        target: 'sor.target',
        solution: 'sor.solution',
        category: 'sor.category',
    },
    sidebar: {
        dashboard: 'sidebar.dashboard',

        // Report
        safetyReport: {
            name: 'sidebar.safetyReport.name',
            create: 'sidebar.safetyReport.create',
            topic: 'sidebar.safetyReport.topic',
            detail: 'sidebar.safetyReport.detail',
            local: 'sidebar.safetyReport.local',
            synthesis: 'sidebar.safetyReport.synthesis',
        },

        // Safety check
        safetyInspections: {
            name: 'sidebar.safetyInspections.name',
            daily: {
                name: 'sidebar.safetyInspections.daily.name',
                create: 'sidebar.safetyInspections.daily.create',
                detail: 'sidebar.safetyInspections.daily.detail',
                synthesis: 'sidebar.safetyInspections.daily.synthesis',
                unapprovedReport: 'sidebar.safetyInspections.daily.unapprovedReport',
                group: 'sidebar.safetyInspections.daily.group',
            },
            periodically: {
                name: 'sidebar.safetyInspections.periodically.name',
                create: 'sidebar.safetyInspections.periodically.create',
                detail: 'sidebar.safetyInspections.periodically.detail',
                synthesis: 'sidebar.safetyInspections.periodically.synthesis',
                unapprovedReport: 'sidebar.safetyInspections.periodically.unapprovedReport',
                group: 'sidebar.safetyInspections.periodically.group',
            },
        },

        // equipment
        equipment: {
            name: 'sidebar.equipment.name',
            list: 'sidebar.equipment.list',
            type: 'sidebar.equipment.type',
            create: 'sidebar.equipment.create',
            report: 'sidebar.equipment.report',
            reportCreate: 'sidebar.equipment.reportCreate',
            checkList: 'sidebar.equipment.checkList',
        },

        // trip
        trip: {
            name: 'sidebar.trip.name',
            trip: 'sidebar.trip.trip',
            report: 'sidebar.trip.report',
            route: 'sidebar.trip.route',
            routeCreate: 'sidebar.trip.routeCreate',
            create: 'sidebar.trip.create',
        },

        //kpi
        kpi: {
            name: 'sidebar.kpi.name',
            create: 'sidebar.kpi.create',
            report: 'sidebar.kpi.report',
            assignment: 'sidebar.kpi.assignment',
            type: 'sidebar.kpi.type',
        },

        //training online
        trainingOnline: {
            name: 'sidebar.trainingOnline.name',
            course: 'sidebar.trainingOnline.course',
            courseCreate: 'sidebar.trainingOnline.courseCreate',
            scores: 'sidebar.trainingOnline.scores',
            myCourse: 'sidebar.trainingOnline.myCourse',
            category: 'sidebar.trainingOnline.category',
        },

        //wpr
        wpr: {
            name: 'sidebar.wpr.name',
            pprCreate: 'sidebar.wpr.pprCreate',
            pprDetail: 'sidebar.wpr.pprDetail',
            levelPPR: 'sidebar.wpr.levelPPR',
            skpiCreate: 'sidebar.wpr.skpiCreate',
            skpiReview: 'sidebar.wpr.skpiReview',
        },

        //riskAssessment
        riskAssessment: {
            name: 'sidebar.riskAssessment.name',
            register: 'sidebar.riskAssessment.register',
            onlineDataBase: 'sidebar.riskAssessment.onlineDataBase',
        },

        //incident report
        incidentReport: {
            name: 'sidebar.incidentReport.name',
            report: 'sidebar.incidentReport.report',
            create: 'sidebar.incidentReport.create',
            config: 'sidebar.incidentReport.config',
        },

        //message
        message: {
            name: 'sidebar.message.name',
        },

        //action
        action: {
            name: 'sidebar.action.name',
        },

        //library
        library: {
            name: 'sidebar.library.name',
        },

        // Configuration
        config: {
            name: 'sidebar.config.name',
            goldenRules: 'sidebar.config.goldenRules',
            riskLevel: 'sidebar.config.riskLevel',
            site: 'sidebar.config.site',
            contractor: 'sidebar.config.contractor',
            area: 'sidebar.config.area',
            department: 'sidebar.config.department',
            account: 'sidebar.config.account',
            dashboard: 'sidebar.config.dashboard',
        },

        //hotline
        hotline: {
            name: 'sidebar.hotline.name',
        },
    },

    pageName: {
        home: 'pageName.home',
        userProfile: 'pageName.userProfile',
        changePassword: 'pageName.changePassword',
        account: 'pageName.account',
        customer: 'pageName.customer',
        area: 'pageName.area',
        department: 'pageName.department',
        riskLevel: 'pageName.riskLevel',
    },

    table: {
        customer: 'table.customer',
        area: 'table.area',
        department: 'table.department',
        riskLevel: 'table.riskLevel',
        find: 'table.find',
        display: 'table.display',
        data: 'table.data',
        to: 'table.to',
        of: 'table.of',
    },

    form: {
        position: 'form.position',
        action: 'form.action',
        fullName: {
            name: 'form.fullName.name',
        },
        title: {
            name: 'form.title.name',
        },
        email: {
            name: 'form.email.name',
        },
        username: {
            name: 'form.username.name',
            placeholder: 'form.username.placeholder',
        },
        phone: {
            name: 'form.phone.name',
            length: 'form.phone.length',
        },
        customerCompanyName: {
            name: 'form.customerCompanyName.name',
        },
        site: {
            name: 'form.site.name',
        },

        area: {
            name: 'form.area.name',
        },

        department: {
            name: 'form.department.name',
        },

        riskLevel: {
            nameVi: 'form.riskLevel.nameVi',
            nameEn: 'form.riskLevel.nameEn',
            name: 'form.riskLevel.name',
            note: 'form.riskLevel.note',
        },

        password: {
            name: 'form.password.name',
            placeholder: 'form.password.placeholder',
            length: 'form.password.length',
            old: 'form.password.old',
            new: 'form.password.new',
            confirm: 'form.password.confirm',
            notMatch: 'form.password.notMatch',
            changePasswordSuccess: 'form.password.changePasswordSuccess',
        },
        customer: {
            name: 'form.customer.name',
        },

        validate: {
            required: 'form.validate.required',
            email: 'form.validate.email',
            nameCannotEmpty: 'form.validate.nameCannotEmpty',
        },
        all: 'form.all',
        done: 'form.done',
        ignore: 'form.ignore',
        clear: 'form.clear',
        name: 'form.name',
        content: 'form.content',
        enterName: 'form.enterName',

        safetyInspections: {
            loadTemplate: 'form.safetyInspections.loadTemplate',
            type: 'form.safetyInspections.type',
            categoryGroup: 'form.safetyInspections.categoryGroup',
            categoryItem: 'form.safetyInspections.categoryItem',
            periodically: 'form.safetyInspections.periodically',
            daily: 'form.safetyInspections.daily',
            templateName: 'form.safetyInspections.templateName',
            createdBy: 'form.safetyInspections.createdBy',
            level: 'form.safetyInspections.level',
            status: 'form.safetyInspections.status',
            pic: 'form.safetyInspections.pic',
            createdDate: 'form.safetyInspections.createdDate',
            closedDate: 'form.safetyInspections.closedDate',
            closeDescriptiton: 'form.safetyInspections.closeDescriptiton',
            totalSafety: 'form.safetyInspections.totalSafety',
            ok: 'form.safetyInspections.ok',
            not: 'form.safetyInspections.not',
            notOkDescription: 'form.safetyInspections.notOkDescription',
            notOkComment: 'form.safetyInspections.notOkComment',
            statusOpen: 'form.safetyInspections.statusOpen',
            statusClosed: 'form.safetyInspections.statusClosed',
            deadline: 'form.safetyInspections.deadline',
            validate: {
                selectedCompaniesEmpty: 'form.safetyInspections.validate.selectedCompaniesEmpty',
                selectedTypeGroupsEmpty: 'form.safetyInspections.validate.selectedTypeGroupsEmpty',
            },
        },
    },

    admin: {
        selectCustomer: 'admin.selectCustomer',
    },

    datatable: {
        language: {
            lengthMenu: 'datatable.language.lengthMenu',
            search: 'datatable.language.search',
            nodata: 'datatable.language.nodata',
            all: 'datatable.language.all',
        },

        // field name
        fullname: 'datatable.fullname',
        authorisation: 'datatable.authorisation',
        phoneNumber: 'datatable.phoneNumber',
        companyName: 'datatable.companyName',
        total: 'datatable.total',
        equipment: {
            code: 'datatable.equipment.code',
            name: 'datatable.equipment.name',
            type: 'datatable.equipment.type',
            quantity: 'datatable.equipment.quantity',
        },
    },

    button: {
        add: 'button.add',
        delete: 'button.delete',
        close: 'button.close',
        update: 'button.update',
        save: 'button.save',
        apply: 'common.apply',
        closeReport: 'button.closeReport',
    },

    modal: {
        confirm: {
            title: 'modal.confirm.title',
            titleClose: 'modal.confirm.titleClose',
            cancel: 'modal.confirm.cancel',
            ok: 'modal.confirm.ok',
            confirmDelete: 'modal.confirm.confirmDelete',
            confirmCreate: 'modal.confirm.confirmCreate',
            confirmUpdate: 'modal.confirm.confirmUpdate',
            confirmClose: 'modal.confirm.confirmClose',
            confirmShowEquipmentReport: 'modal.confirm.confirmShowEquipmentReport',
        },

        action: {
            close: 'modal.action.close',
            save: 'modal.action.save',
        },
    },

    alert: {
        addSuccess: 'alert.addSuccess',
        updateSuccess: 'alert.updateSuccess',
        deleteAccount: 'alert.deleteAccount',
        deleteConfirm: 'alert.deleteConfirm',
        resetPassword: 'alert.resetPassword',
        success: 'alert.success',

        confirm: {
            ok: 'alert.confirm.ok',
            cancel: 'alert.confirm.cancel',
        },
    },

    pages: {
        customer: {
            add: 'pages.customer.add',
            update: 'pages.customer.update',
        },

        area: {
            add: 'pages.area.add',
            update: 'pages.area.update',
        },

        department: {
            add: 'pages.department.add',
            update: 'pages.department.update',
        },

        riskLevel: {
            add: 'pages.riskLevel.add',
            update: 'pages.riskLevel.update',
        },

        signin: {
            name: 'pages.signin.name',
            subTitle: 'pages.signin.subTitle',
            rememberMe: 'pages.signin.rememberMe',
            forgotPassword: 'pages.signin.forgotPassword',
        },
        USCreateAccount: 'pages.USCreateAccount',
        USEditAcount: 'pages.USEditAcount',
        CreateCategory: 'pages.CreateCategory',
        UpdateCategory: 'pages.UpdateCategory',
        UpdateRiskLevel: 'pages.UpdateRiskLevel',
    },
    noData: 'noData',
    save: 'save',
    contractor: 'contractor',
    MENUSor: 'MENUSor',
    ReportEquipment: 'ReportEquipment',
    MENUPpr: 'MENUPpr',
    KPIS: 'KPIs',
    trip: {
        commodities: 'trip.commodities',
        tripInformation: 'trip.tripInformation',
        regularTrip: 'trip.regularTrip',
        equipment: 'trip.equipment',
        operator: 'trip.operator',
        from: 'trip.from',
        to: 'trip.to',
        reason: 'trip.reason',
        estimatedDistance: 'trip.estimatedDistance',
        estimatedTimeFrom: 'trip.estimatedTimeFrom',
        estimatedTimeTo: 'trip.estimatedTimeTo',
        carryingInformation: 'trip.carryingInformation',
        passengers: 'trip.passengers',
        note: 'trip.note',
        contact: 'trip.contact',
        approval: 'trip.approval',
        routeInformation: 'trip.routeInformation',
        changeRouteRequest: 'trip.changeRouteRequest',
        route: 'trip.route',
        stop: 'trip.stop',
        addStop: 'trip.addStop',
        stopIndex: 'trip.stopIndex',
        safetyInformation: 'trip.safetyInformation',
        addSafetyInformation: 'trip.addSafetyInformation',
        distance: 'trip.distance',
        image: 'trip.image',
        delete: 'trip.delete',
        status: {
            active: 'trip.status.active',
            approved: 'trip.status.approved',
            completed: 'trip.status.completed',
            processing: 'trip.status.processing',
            refused: 'trip.status.refused',
        },
        report: {
            totalTrip: 'trip.report.totalTrip',
            totalDistance: 'trip.report.totalDistance',
        },
    },
};

export default Translations;
