import { InputField, TextAreaField } from 'components/FormControl';
import React, { FormEventHandler, Fragment, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, Col, Row } from 'react-bootstrap';
import { Control, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EntityConst } from 'constants/EntityConst';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import DateTimeField from 'components/FormControl/DateTimeField';
import UserSelectField from 'components/Common/UserSelect';
import { PencilSquare, PlusCircleFill, Trash } from 'react-bootstrap-icons';
import { AddButton, DeleteButton, DeleteIconButton, DownloadButton, SaveButton, UpdateButton } from 'components/Button';
import UploadFileField from 'components/FormControl/UploadFileField';
import { LDContentRequest, LDFileRequest, LegalDocumentDetailResponse, LegalDocumentFilterRequest, LegalDocumentRequest } from 'api/v2/legal-document/legalDocument.models';
import { FileResponse } from 'api/models';
import LinkFileDownload from 'components/Common/LinkFile';
import { LDContentType } from 'enum/legalDocument';
import LegalDocumentTagSelect from './component/legalDocumentTagSelect';
import legalDocumentApi from 'api/v2/legal-document/legalDocumentApi';
import LegalDocGroupSelect from './component/legalDocumentGroupSelect';
import { useAuth } from 'providers/contexts';
import ToastConfirm from 'components/Modal/ToastConfirm';
import { ModalDetailProps } from 'models/modalDetailProps';
import { ModalHookComponentProps } from 'components/ModalHook';
import { useNavigate } from 'react-router-dom';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultToDate.setDate(defaultFromDate.getDate() + 365);

interface LegalDocumentCreatePageProps {
    detail?: LegalDocumentDetailResponse | null;
}
export default function LegalDocumentCreatePage({ detail }: LegalDocumentCreatePageProps) {
    const [isReadOnly, seIsReadOnly] = useState<boolean>(false);
    const [rules, setRules] = useState<string[]>([]);
    const [requiments, setRequiments] = useState<string[]>([]);
    const [applyObjects, setApplyObjects] = useState<string[]>([]);
    const [files, setFiles] = useState<LDFileRequest[]>([]);
    const navigate = useNavigate();
    const { userPms } = useAuth();
    useEffect(() => {
        seIsReadOnly(detail != null && detail != undefined);
        if (detail) {
            reset({
                ...detail,
                tagIds: detail.tags.map((n) => n.id),
            });
            setRules(detail.contents.filter((n) => n.contentType == LDContentType.RULE).map((n) => n.content) ?? []);
            setApplyObjects(detail.contents.filter((n) => n.contentType == LDContentType.APPLY_OBJECT).map((n) => n.content) ?? []);
            setRequiments(detail.contents.filter((n) => n.contentType == LDContentType.REQUIREMENT).map((n) => n.content) ?? []);
            setFiles(detail.files ?? []);
        }
    }, []);

    const schema = yup.object().shape({
        title: yup.string().required('Title is required.').max(EntityConst.longString, `Title must be less than ${EntityConst.longString} characters.`),
        property: yup.string().required('Property is required.').max(EntityConst.descriptionVeryLong, `Property must be less than ${EntityConst.descriptionVeryLong} characters.`),
        referenceNumber: yup.string().required('Reference number is required.').max(EntityConst.shortString, `Reference number must be less than ${EntityConst.shortString} characters.`),
        issuePlace: yup.string().required('Issue place is required.').max(EntityConst.longString, `Issue place must be less than ${EntityConst.longString} characters.`),
        effectiveDateFrom: yup.date().required('Effective date from is required.').max(yup.ref('effectiveDateTo'), 'Effective date from must be earlier than effective date to.'),
        effectiveDateTo: yup.date().required('Effective date to is required.'),
        followerId: yup.string().notRequired().nullable(),
        groupId: yup.string().required(),
        tagIds: yup.array(),
        files: yup.array(),
        contents: yup.array(),
    });

    const {
        control,
        getValues,
        reset,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const {
        control: controlRule,
        getValues: getValuesRule,
        handleSubmit: handleSubmitRule,
        reset: resetRule,
    } = useForm({
        resolver: yupResolver(
            yup.object().shape({
                content: yup.string().required(),
            })
        ),
    });

    const {
        control: controlApplyObject,
        getValues: getValuesApplyObject,
        handleSubmit: handleSubmitApplyObject,
        reset: resetApplyObject,
    } = useForm({
        resolver: yupResolver(
            yup.object().shape({
                content: yup.string().required(),
            })
        ),
    });
    const {
        control: controlRequirement,
        getValues: getValuesRequirement,
        handleSubmit: handleSubmitRequirement,
        reset: resetRequirement,
    } = useForm({
        resolver: yupResolver(
            yup.object().shape({
                content: yup.string().required(),
            })
        ),
    });

    // Rule - Điều khoản
    const onAddRule = () => {
        let text = getValuesRule().content;
        if (text) {
            setRules([text, ...rules]);
            resetRule({ content: '' });
        }
    };
    const onDeleteRule = (index: number) => {
        rules.splice(index, 1);
        let newRules = [...rules];
        setRules(newRules);
    };

    // applyObject - Đối tượng ấp dụng
    const onAddApplyObject = () => {
        let text = getValuesApplyObject().content;
        if (text) {
            setApplyObjects([text, ...applyObjects]);
            resetApplyObject({ content: '' });
        }
    };
    const onDeleteApplyObject = (index: number) => {
        applyObjects.splice(index, 1);
        let newDatas = [...applyObjects];
        setApplyObjects(newDatas);
    };
    // Requirement - Đối tượng ấp dụng
    const onAddRequirement = () => {
        let text = getValuesRequirement().content;
        if (text) {
            setRequiments([text, ...requiments]);
            resetRequirement({ content: '' });
        }
    };
    const onDeleteRequirement = (index: number) => {
        requiments.splice(index, 1);
        let newDatas = [...requiments];
        setRequiments(newDatas);
    };

    // File
    const handleUploadedFile = (fileUploaded: FileResponse) => {
        console.log('uploaf done', fileUploaded);
        let file: LDFileRequest = {
            ...fileUploaded,
            id: null,
        };
        setFiles([file, ...files]);
    };
    const onDeleteFile = (index: number) => {
        files.splice(index, 1);
        let newDatas = [...files];
        setFiles(newDatas);
    };

    // Delete all LD
    const onDelete = () => {
        detail &&
            legalDocumentApi
                .delete(detail?.id)
                .then((n) => {
                    navigate('', { replace: true });
                })
                .catch((n) => {});
    };
    const collectInfo = () => {
        let form = getValues();
        let contentRules: LDContentRequest[] = rules.map((n) => ({
            title: '',
            content: n,
            contentType: LDContentType.RULE,
        }));
        let contentRequirepment: LDContentRequest[] = requiments.map((n) => ({
            title: '',
            content: n,
            contentType: LDContentType.REQUIREMENT,
        }));
        let contentApplyObject: LDContentRequest[] = applyObjects.map((n) => ({
            title: '',
            content: n,
            contentType: LDContentType.APPLY_OBJECT,
        }));
        var payload: LegalDocumentRequest = {
            ...form,
            contents: [...contentRules, ...contentRequirepment, ...contentApplyObject],
            files: files,
            tagIds: form.tagIds?.map((n) => n as string) ?? [],
        };
        return payload;
    };
    const onAdd = () => {
        legalDocumentApi
            .create(collectInfo())
            .then((n) => {
                reset({});
                setRules([]);
                setRequiments([]);
                setFiles([]);
                setApplyObjects([]);
            })
            .catch((n) => {});
    };

    const onUpdate = () => {
        let payload = collectInfo();
        console.log(payload);
        legalDocumentApi
            .update(payload)
            .then((n) => {})
            .catch((n) => {});
    };

    return (
        <Fragment>
            <div>
                <form onSubmit={handleSubmit(detail ? onUpdate : onAdd)}>
                    <Card>
                        <Card.Header>
                            <div className="d-flex justify-content-between">
                                <h5>{lang.text(Translations.common.generalInfo)}</h5>
                                <div className="d-flex ">
                                    {!isReadOnly && <SaveButton type="submit" />}
                                    <DeleteButton onClick={onDelete} isRender={userPms.ldAdmin && isReadOnly} />
                                    <UpdateButton onClick={() => seIsReadOnly(false)} className="mx-2" isRender={userPms.ldAdmin && isReadOnly} />
                                </div>
                            </div>
                        </Card.Header>

                        <Card.Body>
                            <Row>
                                <InputField md={6} name={'title'} control={control} defaultValue={''} label={lang.text(Translations.legalDocument.title)} readOnly={isReadOnly} />
                                <InputField md={6} name={'issuePlace'} control={control} defaultValue={''} label={lang.text(Translations.legalDocument.issuePlace)} readOnly={isReadOnly} />
                                <InputField md={3} name={'referenceNumber'} control={control} defaultValue={''} label={lang.text(Translations.legalDocument.referenceNumber)} readOnly={isReadOnly} />
                                <UserSelectField
                                    md={3}
                                    name="followerId"
                                    isMutiple={false}
                                    readOnly={isReadOnly}
                                    label={lang.text(Translations.legalDocument.follower)}
                                    control={control}
                                    required={false}
                                />
                                <DateTimeField
                                    md={3}
                                    name={'effectiveDateFrom'}
                                    label={lang.text(Translations.legalDocument.effectiveDateFrom)}
                                    control={control}
                                    readOnly={isReadOnly}
                                    defaultDate={defaultFromDate}
                                />
                                <DateTimeField
                                    md={3}
                                    name={'effectiveDateTo'}
                                    label={lang.text(Translations.legalDocument.effectiveDateTo)}
                                    control={control}
                                    readOnly={isReadOnly}
                                    defaultDate={defaultToDate}
                                />
                                <LegalDocumentTagSelect disable={isReadOnly} md={6} isCanCreate={!isReadOnly} control={control} isMulti={true} name="tagIds" />
                                <LegalDocGroupSelect disable={isReadOnly} md={6} control={control} isMulti={false} name="groupId" />
                                <TextAreaField md={12} name={'property'} control={control} defaultValue={''} label={lang.text(Translations.legalDocument.property)} readOnly={isReadOnly} />
                            </Row>
                        </Card.Body>
                    </Card>
                </form>
                <ContentForm
                    readonly={isReadOnly}
                    reset={resetRule}
                    onSubmit={handleSubmitRule(onAddRule)}
                    onDelete={onDeleteRule}
                    control={controlRule}
                    datas={rules}
                    headerTitle={lang.text(Translations.legalDocument.rules)}
                />

                <ContentForm
                    readonly={isReadOnly}
                    reset={resetApplyObject}
                    onSubmit={handleSubmitApplyObject(onAddApplyObject)}
                    onDelete={onDeleteApplyObject}
                    control={controlApplyObject}
                    datas={applyObjects}
                    headerTitle={lang.text(Translations.legalDocument.applyObject)}
                />

                <ContentForm
                    readonly={isReadOnly}
                    reset={resetRequirement}
                    onSubmit={handleSubmitRequirement(onAddRequirement)}
                    onDelete={onDeleteRequirement}
                    control={controlRequirement}
                    datas={requiments}
                    headerTitle={lang.text(Translations.legalDocument.requirements)}
                />
                <Card>
                    <Card.Header>
                        <h5 className="">{lang.text(Translations.common.upload)} </h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            {!isReadOnly && (
                                <Col md={5}>
                                    <UploadFileField md={6} name="filePath" control={control} onUploaded={handleUploadedFile} />
                                </Col>
                            )}
                            <Col md={isReadOnly ? 12 : 7}>
                                <ul>
                                    {files &&
                                        files.map((n, i) => (
                                            <li key={i}>
                                                {!isReadOnly && <Trash onClick={() => onDeleteFile(i)} color="red" className="hand" />}

                                                <LinkFileDownload path={n.filePath} fileName={n.fileName} />
                                            </li>
                                        ))}
                                </ul>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </Fragment>
    );
}

const ContentForm = (props: {
    readonly: boolean;
    reset: (n: object) => void;
    onSubmit: any;
    control: Control<{ content: string }>;
    onDelete: (i: number) => void;
    headerTitle: string;
    datas: string[];
}) => {
    return (
        <Card>
            <Card.Header>
                <h5 className="">{props.headerTitle} </h5>
            </Card.Header>
            <Card.Body>
                <Row>
                    {!props.readonly && (
                        <Col md={5}>
                            <form onSubmit={props.onSubmit}>
                                <TextAreaField md={12} name={'content'} control={props.control} defaultValue={''} label={''} />
                                <div className="d-flex justify-content-end">
                                    <AddButton isRender={true} type="submit" />
                                </div>
                            </form>
                        </Col>
                    )}
                    <Col md={props.readonly ? 12 : 7}>
                        <ul>
                            {props.datas &&
                                props.datas.map((n, i) => (
                                    <li key={i}>
                                        {!props.readonly && (
                                            <Fragment>
                                                <Trash onClick={() => props.onDelete(i)} color="red" className="hand" />
                                                <PencilSquare
                                                    onClick={() => {
                                                        props.reset({ content: n });
                                                        props.onDelete(i);
                                                    }}
                                                    className="hand text-info mx-2"
                                                />
                                            </Fragment>
                                        )}
                                        {n}
                                    </li>
                                ))}
                        </ul>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
// Module: Legislations – Tuân thủ pháp luật

// Admin
// 1.	Tạo mới tài liệu
// a.	Số hiệu văn bản
// -	Tên tài liệu / văn bản
// -	Thuộc tính văn bản
// -	Cơ quan ban hành
// -	Hiệu lực từ ngày – đến ngày
// -	Tải file đính kèm (PDF)
// b.	Nội dung (có thể thêm các nội dung như trong phần các bước thực hiện của risk assessment)
// -	Điều khoản (ví dụ: điều 1, khoản a…)- (có thể thêm các điều khoản như trong phần các bước thực hiện của risk assessment)
// -	Đối tượng áp dụng (có thể thêm các điều khoản như trong phần các bước thực hiện của risk assessment)
// -	Yêu cầu thực hiện / Nội dung cần làm(có thể thêm các điều khoản như trong phần các bước thực hiện của risk assessment)
// -	Người theo dõi (tính năng gán người theo dõi: All, chọn chức danh)

// 2.	Xoá / hiệu chỉnh
// a.	Tìm kiếm theo:
// -	Số hiệu văn bản
// -	Tên tài liệu / văn bản
// -	Thuộc tính văn bản
// -	Cơ quan ban hành
// 3.	Tìm kiếm
// a.	Từ khoá (lấy từ nội dung và đối tượng áp dụng)
// b.	Số hiệu văn bản
// c.	Tên tài liệu / văn bản
// d.	Thuộc tính văn bản
// e.	Cơ quan ban hành
// 4.	In danh sách
// -	In tất cả danh sách các văn bản
// -	Tìm kiếm theo nội dung

// Người dùng

// 5.	Tìm kiếm
// a.	Từ khoá (lấy từ nội dung và đối tượng áp dụng)
// b.	Số hiệu văn bản
// c.	Tên tài liệu / văn bản
// d.	Thuộc tính văn bản
// e.	Cơ quan ban hành
// 6.	In danh sách
// -	In tất cả danh sách các văn bản
// -	Tìm kiếm theo nội dung
