import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import { SelectFieldProps, SelectOption } from '../SelectField';
import { BaseFormControlProps } from '../baseFormProps';
import { useController } from 'react-hook-form';
import { debounce } from 'helper/debounce';

export interface SelectSearchFieldProps extends SelectFieldProps {
    onSearch?: (value: string) => void;
    onAsyncSearch?: (value: string) => void;
    isSearch?: boolean;
    isLoading?: boolean;
    setLoading?: (loading: boolean) => void;
}
const SelectSearchField = ({
    options,
    onSearch,
    name,
    control,
    md,
    label,
    isMutiple,
    displayProp,
    disable,
    onValueChange,
    onValuesChange,
    isLoading,
    setLoading,
    readOnly,
    placeholder,
    onAsyncSearch,
}: SelectSearchFieldProps) => {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
    });
    useEffect(() => {
        console.log('vao optionchanged', field.value);
        if (options?.hasValue() && field.value) {
            onChangeSelected(field.value);
        }
    }, [options]);
    const onChangeSelected = (e: any) => {
        let val = isMutiple ? e.map((x: any) => x.value) : e[0]?.value;
        field.onChange(val);
        if (isMutiple) onValuesChange && onValuesChange(val);
        else onValueChange && onValueChange(val);
    };
    return (
        <div className={'col-md-' + md}>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                {onSearch || onAsyncSearch ? (
                    <AsyncTypeahead
                        // filterBy={true}
                        id={name}
                        isLoading={!!isLoading}
                        labelKey={'label'}
                        minLength={0}
                        onSearch={(e) => !onAsyncSearch && onSearch?.(e)}
                        onInputChange={(e) => {
                            if (!e) {
                                onAsyncSearch?.('');
                                return;
                            }
                            onAsyncSearch?.(e);
                        }}
                        onChange={onChangeSelected}
                        options={options ?? []}
                        placeholder={placeholder || 'Search ...'}
                        multiple={isMutiple}
                        disabled={disable}
                        useCache={false}
                    />
                ) : (
                    <Typeahead
                        selected={
                            Array.isArray(field.value)
                                ? field.value?.map((v: any) => ({
                                      value: v,
                                      label: options?.find((u) => u.value === v)?.label || '',
                                  }))
                                : []
                        }
                        labelKey={(option: any) => {
                            return option.label;
                        }}
                        multiple={isMutiple}
                        onChange={(e) => {
                            console.log(name, 'vao on changed');
                            onChangeSelected(e);
                            field.onChange(e?.map((v: any) => v.value));
                        }}
                        options={options ?? []}
                        placeholder={placeholder}
                        disabled={readOnly}
                    />

                    // <Typeahead
                    //     id={name}
                    //     labelKey={displayProp ?? 'label'}
                    //     onChange={onChangeSelected}
                    //     options={options ?? []}
                    //     placeholder="Choose  "
                    //     multiple={isMutiple}
                    //     disabled={disable}
                    // />
                )}
                <Form.Control.Feedback type="invalid">{error?.message}</Form.Control.Feedback>
            </Form.Group>
        </div>
    );
};

export default SelectSearchField;
