import { PMS } from 'router/const/roles';
import { OrganizationUnitResponse } from '../../../models/organization';
import { PaginationBase, PaginationBaseRequest } from '../../../models/common';

export interface TokenProfile {
    isSuperAdmin: boolean;
    fullName: string;
    roles: PMS[];
    phoneNumber: string | null;
    email: string | null;
    userId: string;
    isCusomterAdmin: boolean;
    roleId: string;
    tenantId: string;
    pms: ProfilePms;
}

export const defaultProfilePms = (isSupperAdmin: boolean) => {
    let rs: ProfilePms = {
        supperAdmin: isSupperAdmin,
        roleAdmin: isSupperAdmin,
        roleView: isSupperAdmin,
        ldAdmin: isSupperAdmin,
        ldRead: isSupperAdmin,
        ldTagCreate: isSupperAdmin,
        orgAdmin: isSupperAdmin,
        tenantAdmin: isSupperAdmin,
        user: {
            admin: isSupperAdmin,
            readOnly: isSupperAdmin,
        },
        equipment: {
            admin: isSupperAdmin,
            readOnly: isSupperAdmin,
        },
        trip: {
            admin: isSupperAdmin,
            driver: isSupperAdmin,
        },
    };
    return rs;
};

export interface ProfilePms {
    supperAdmin: boolean;

    roleAdmin: boolean;
    roleView: boolean;

    /**Legal document */
    ldAdmin: boolean;
    /**Legal document */
    ldRead: boolean;
    /**Legal document */
    ldTagCreate: boolean;

    orgAdmin: boolean;
    tenantAdmin: boolean;
    user: {
        admin: boolean;
        readOnly: boolean;
    };
    equipment: {
        admin: boolean;
        readOnly: boolean;
    };
    trip: {
        admin: boolean;
        driver: boolean;
    };
}

export interface UserDetailResponse {
    id: string;
    phoneNumber: string;
    email: string;
    avatar: string;
    status: string;
    title: string;
    organizationUnits: OrganizationUnitResponse[] | null;
    tenantId: string;
    roleId: string;
    roleName: string;
    fullName: string;
    userName: string;
    qRCode?: string;

    //heath
    weight?: string;
    height?: string;
    bloodType?: string;
    healthStatus?: string;
    healthCheckResultDate?: string;

    profileCourses: ProfileCourseRequest[] | null;
    profileCertificates: ProfileCertificateRequest[] | null;
    profileIncentiveDisciplinaries: ProfileIncentiveDisciplinaryRequest[] | null;
}
export interface UserSimpleResponse {
    id: string;
    fullName: string;
}
export interface UserResponse {
    id: string;
    fullName: string;
    phoneNumber: string;
    email: string;
    avatar: string;
    status: string;
    title: string;
}
export interface UserFilterRequest extends PaginationBaseRequest {
    companyId?: string;
    areaId?: string;
}

export interface UserCreateRequest {
    fullName: string;
    email?: string;
    phoneNumber?: string;
    userName: string;
    password: string;
    title?: string;
    language?: string;
    roleId: string;
    avatar?: string;
    cardNumber?: string;
    employeeCode?: string;
    dateOfBirth?: string;
    permanentAddress?: string;
    emergencyPhoneNumber?: string;
    weight?: string;
    height?: string;
    bloodType?: string;
    healthStatus?: string;
    healthCheckResultDate?: string;
    organizationUnitIds: string[];
    profileCertificates?: ProfileCertificateRequest[];
    profileCourses?: ProfileCourseRequest[];
    profileIncentiveDisciplinarys?: ProfileIncentiveDisciplinaryRequest[];
}
export interface UserUpdateRequest extends UserCreateRequest {
    id: string;
}
export interface ProfileCertificateRequest {
    id?: string;
    name: string;
    expiredDate?: Date;
    issueDate?: Date;
    issuePlace?: string;
    pathCertificate?: string;
    fileName?: string;
}

export interface ProfileCourseRequest {
    id?: string;
    name: string;
    organizeUnitName?: string;
    startDate?: Date;
    expiredDate?: Date;
    status?: string;
    filePath?: string;
    fileName?: string;
}

export interface ProfileIncentiveDisciplinaryRequest {
    id?: string;
    type: string;
    date?: Date;
    content: string;
    decisionMake?: string;
    filePath?: string;
    fileName?: string;
}
