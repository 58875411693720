import Translations from 'i18n/translation';
import { Button } from 'react-bootstrap';
import { PlusCircleFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { BaseButtonProps } from './BaseButton.props';
import Color from 'enum/color';
import { lang } from 'i18n/lang';
import { BaseButton } from './BaseButton';

export function AddButton(props: BaseButtonProps) {
    return <BaseButton {...props} color={Color.primary} icon={<PlusCircleFill />} text={props.text ?? lang.text(Translations.common.addNew)} />;
}

export interface AddButtonNavigateProps extends BaseButtonProps {
    url?: string;
}
export function AddButtonNavigate(props: AddButtonNavigateProps) {
    return props?.url ? (
        <Link to={props?.url} className={props.className + ' d-flex justify-content-end hvr-glow'}>
            <BaseButton {...props} color={Color.primary} icon={<PlusCircleFill />} text={props.text ?? lang.text(Translations.common.addNew)} className="" />;
        </Link>
    ) : (
        <BaseButton {...props} color={Color.primary} icon={<PlusCircleFill />} text={props.text ?? lang.text(Translations.common.addNew)} className="" />
    );

}
