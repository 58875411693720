import userApi from 'api/v2/user/userApi';
import { SelectField, SelectFieldProps, SelectOption } from 'components/FormControl';
import { generateGUID } from 'helper';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useController } from 'react-hook-form';

export interface UserSelectFieldProps extends SelectFieldProps {
    companyId?: string;
}

export const UserSelectField: React.FC<UserSelectFieldProps> = (props: UserSelectFieldProps) => {
    const [users, setUsers] = useState<SelectOption[]>([]);

    const {
        field,
        fieldState: { error, invalid },
    } = useController({
        name: props?.name,
        control: props.control,
    });

    useEffect(() => {
        loadUsers();
        console.log('Comapny ID: ', props.companyId);
    }, [props.companyId]);
    const loadUsers = () => {
        userApi
            .searchDropdown('', props.companyId)
            .then((users) => {
                setUsers(users);
            })
            .catch((n) => {});
    };
    useEffect(() => loadUsers, []);
    const id = generateGUID();
    return props?.isMutiple ? (
        <div className={`col-md-${props?.md}`}>
            <h1>{props.companyId}</h1>
            <Form.Group className="">
                <Form.Label>{props?.label}</Form.Label>
                <Typeahead
                    selected={
                        Array.isArray(field.value)
                            ? field.value?.map((v: any) => ({
                                  value: v,
                                  label: users?.find((u) => u.value === v)?.label || '',
                              }))
                            : []
                    }
                    id={id}
                    labelKey={(option: Option) => {
                        return (option as any).label;
                    }}
                    multiple
                    onChange={(e) => {
                        props?.onChange?.(e as any);
                        field.onChange(e?.map((v: any) => v.value));
                    }}
                    options={users}
                    placeholder={props?.placeholder}
                    disabled={props?.readOnly}
                />
            </Form.Group>
        </div>
    ) : (
        <SelectField {...props} label={props.label} options={users} name={props.name} isMutiple={false} />
    );
};
export default UserSelectField;
