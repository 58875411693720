import _ from 'lodash';
import React, { useEffect } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Row, Col } from 'react-bootstrap';
import { Control, useController } from 'react-hook-form';
import { BaseFormControlProps } from '../baseFormProps';
import { generateGUID } from 'helper';

export interface SelectFieldProps extends BaseFormControlProps {
    options?: SelectOption[];
    onValuesChange?: (values: any[]) => void;
    onValueChange?: (value: any) => void;
    onSelectedOptionChange?: (value?: SelectOption | undefined | null) => void; //onValueChange for only Id, onValueOptionChange for Id and Label
    displayProp?: string;
    isMutiple?: boolean;
    groupName?: string;
    onSearch?: ((value: string) => void) | undefined;
    isAddAll?: boolean;
    unSelectValue?: number | string;
}

export interface SelectOption {
    label?: string;
    value: number | string;
}

export const SelectField: React.FC<SelectFieldProps> = ({
    name,
    control,
    label,
    disabled = false,
    options = [],
    formGroupClasses = '',
    defaultValue,
    onValuesChange,
    onValueChange,
    onSelectedOptionChange,
    required,
    isMutiple = false,
    md,
    isAddAll = true,
    readOnly,
    unSelectValue,
}) => {
    const {
        field,
        fieldState: { error, invalid },
    } = useController({
        name,
        control,
        defaultValue,
    });
    const inValidClass = error?.message ? 'is-invalid' : '';

    useEffect(() => {
        if (onValueChange) onValueChange(field.value);
        onSelectedOptionChange && onSelectedOptionChange(options.find((option) => option.value == field.value));
    }, [field.value]);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;
        if (isMutiple) {
            const selectedOptions = Array.from(e.target.selectedOptions).map((option) => option.value);
            field.onChange(selectedOptions);
        } else {
            if (field.value != value) {
                field.onChange(value);
            }
        }
    };
    return (
        <div className={`col-md-${md}`}>
            <Form.Group className={`mb-3 ${formGroupClasses}`}>
                <FormGroup className={formGroupClasses}>
                    <Form.Label>
                        {label} {required && <span className="text-danger">*</span>}
                    </Form.Label>
                    <Form.Select
                        {...field}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        id={generateGUID()}
                        disabled={readOnly}
                        multiple={isMutiple}
                        className={`${inValidClass}`}
                        required={required}
                    >
                        {isAddAll && <option value={unSelectValue ?? ''}>{`--- select --- `}</option>}
                        {options?.map((option) => (
                            <option key={option.value} value={option.value ?? ''}>
                                {option.label}
                            </option>
                        ))}
                    </Form.Select>
                    <div className="invalid-feedback">{error?.message}</div>
                    {/* <Form.Control.Feedback type="invalid">{error?.message}</Form.Control.Feedback> */}
                </FormGroup>
            </Form.Group>
        </div>
    );
};
